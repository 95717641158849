<template>
    <div class="mt-4 d-flex flex-column gap-3">
        <template
            v-for="action in list"
            :key="[action.type, action.order].join('_')"
        >
            <NdxButton
                v-if="action.type === 'quickCheckOut'"
                @click="$emit('quick-checkout')"
                :disabled="!orderBtnActive || basketActionRunning"
                :variant="action.variant"
            >
                {{ getQuickCheckOutLabel(action) }}
            </NdxButton>

            <template v-else-if="action.type === 'addToBasket'">
                <NdxButton
                    v-if="product.campaignId"
                    @click="$emit('add-to-basket-with-cr')"
                    :disabled="!hasBasket || !orderBtnActive || basketActionRunning || !showIndividualization"
                    :variant="action.variant"
                >
                    {{ action.label.length > 0 ? action.label : $t('btn.startProjectRun') }}
                </NdxButton>
                <NdxButton
                    v-else
                    @click="$emit('add-to-basket')"
                    :disabled="!hasBasket || !orderBtnActive || basketActionRunning || allBundleItemsSetZero"
                    :variant="action.variant"
                >
                    {{ action.label.length > 0 ? action.label : $t('label.addToBasket') }}
                </NdxButton>
            </template>

            <NdxButton
                v-else-if="action.type === 'customLink'"
                @click="onCustomClick(action)"
                :variant="action.variant"
            >
                {{ action.label }}
            </NdxButton>

            <hr v-else-if="action.type === 'separator'">
        </template>
    </div>
</template>

<script>
    import NdxButton from "../../library/NdxButton.vue";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'CallToActions',
        components: {NdxButton},
        emits: ['add-to-basket-with-cr', 'add-to-basket', 'quick-checkout'],
        props: {
            product: {
                type: Object
            },
            orderBtnActive: {
                type: Boolean
            },
            basketActionRunning: {
                type: Boolean
            },
            allBundleItemsSetZero: {
                type: Boolean
            }
        },
        computed: {
            ...mapState({
                shopShowQuickCheckoutBtn: state => state.shop.productDetails.showQuickCheckoutBtn,
                showIndividualization: state => state.shop.productDetails.showIndividualization
            }),
            ...mapGetters('basket', [
                'getBasketTextQuickCheckout'
            ]),
            hasBasket() {
                return !!this.$store.getters['shop/getRouteByType']('basket');
            },
            list() {
                const defaultConfig = [{
                    type: 'addToBasket',
                    active: true,
                    order: 1,
                    variant: 'primary',
                    label: ''
                }, {
                    type: 'quickCheckOut',
                    active: true,
                    order: 2,
                    variant: 'secondary',
                    label: ''
                }];
                const list = this.product.actionConfig ? this.product.actionConfig : defaultConfig;
                return list.filter((item) => {
                    if (item.type === 'customLink' && !(item.link.length && item.label.length)) {
                        return false;
                    }
                    if (item.type === 'quickCheckOut' && !this.showQuickCheckoutBtn) {
                        return false;
                    }
                    return item.active;
                });
            },
            showQuickCheckoutBtn() {
                // products with individualization has no quickCheckout
                if (this.product.campaignId) {
                    return false;
                }
                return this.shopShowQuickCheckoutBtn;
            }
        },
        methods: {
            onCustomClick(action) {
                window.open(action.link, action.target);
            },
            getQuickCheckOutLabel(action) {
                if (action.label.length > 0) {
                    return action.label;
                }
                return this.getBasketTextQuickCheckout
                    ? this.getBasketTextQuickCheckout
                    : this.$t('btn.quickCheckout');
            }
        }
    };
</script>
