<template>
    <div
        v-if="widgetIsEmpty"
        class="no_data_msg"
    >{{ $t('message.result_no_data') }}</div>

    <div v-else>
        <div v-if="showCategoryHeader" class="category-header">
            <div class="category-name">{{ currentCategory.name }}</div>
            <div
                v-if="currentCategory && currentCategory.description.length > 0"
                class="category-description preserveLineBreaks"
            >
                {{ multiLineText(currentCategory.description) }}
            </div>
        </div>
        <div class="d-flex align-items-center pb-3 gap-5">
            <NdxListHeader
                v-if="!smallWidth"
                class="w-auto pb-0"
                v-model:view-mode="viewMode"
            />
            <NdxButton
                v-if="showBackBtn"
                variant="secondary"
                @click="gotoParentCategory"
            >{{ $t('btn.back') }}
            </NdxButton>
        </div>
        <div
            v-if="categories.length > 0"
            class="card-container"
            :class="{['viewmode-' + viewModeComputed]: true}"
        >
            <NdxCategoryCard
                v-for="category in categories"
                :key="category.id"
                :card-img="category.image"
                :title="category.name"
                :description="category.description"
                :variant="viewModeComputed"
                :background-color="category.backgroundColor"
                :foreground-color="category.iconColor"
                @card-click="() => gotoCategoryPage(category.id)"
            />
        </div>
        <NdxListing
            v-if="currentCategory?.id"
            store-action="assets/getEntries"
            :filter-criteria="filterCriteria"
            :page-sizes="[10]"
            :has-additional-content="categories.length > 0"
            @items-changed="updateItems"
        >
            <div
                class="card-container"
                :class="{['viewmode-' + viewModeComputed]: true}"
            >
                <NdxAssetEntryCard
                    v-for="entry of entries"
                    :key="entry.id"
                    :entry="entry"
                    :variant="viewModeComputed"
                    @click="onEntryClick(entry)"
                />
            </div>
        </NdxListing>
    </div>
</template>

<script>
    import { multiLineText } from "@utilities/ndxText";
    import NdxCategoryCard from "../library/NdxCategoryCard.vue";
    import NdxButton from "../library/NdxButton.vue";
    import NdxAssetEntryCard from "../library/NdxAssetEntryCard.vue";
    import NdxListHeader from "../library/NdxListHeader.vue";
    import NdxListing from "../library/NdxListing.vue";

    export default {
        name: 'AssetsWidget',
        components: {NdxListing, NdxListHeader, NdxAssetEntryCard, NdxButton, NdxCategoryCard},
        props: {
            widget: Object,
            position: String
        },
        data() {
            return {
                viewMode: 'card',
                currentCategory: null,
                categories: [],
                entries: [],

                loading: false,

                styleguides: []
            };
        },
        computed: {
            viewModeComputed() {
                return this.smallWidth ? 'list-narrow' : this.viewMode;
            },
            smallWidth() {
                return ['left', 'right'].includes(this.position);
            },
            showCategoryHeader() {
                return !!(this.currentCategory &&
                    this.currentCategory.id !== this.$store.getters['shop/productRootFolderId']);
            },
            showBackBtn() {
                if (this.currentCategory !== null && this.styleguides.includes(this.currentCategory.id) &&
                    this.styleguides.length < 2
                ) {
                    return false;
                }
                return this.currentCategory !== null;
            },
            widgetIsEmpty() {
                return !this.loading && this.currentCategory === null && this.categories.length === 0;
            },
            filterCriteria() {
                return {
                    categoryId: this.currentCategory.id
                };
            }
        },
        mounted() {
            this.styleguides = this.widget.config.styleguides;
            this.loadRootCategories();
        },
        methods: {
            multiLineText,
            loadRootCategories() {
                if (this.styleguides.length === 1) {
                    this.gotoCategoryPage(this.styleguides[0]);
                } else {
                    this.loading = true;
                    this.$store.dispatch('assetCategories/getSubtreeBulk', {
                        idList: this.styleguides
                    }).then((resultList) => {
                        this.categories = [];
                        for (let result of resultList) {
                            if (result) {
                                this.categories.push(result);
                            }
                        }
                        this.currentCategory = null;
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            gotoCategoryPage(categoryId) {
                this.loading = true;
                this.$store.dispatch('assetCategories/getSubtree', {
                    rootId: categoryId
                }).then((result) => {
                    this.currentCategory = result;
                    this.categories = result ? result.children : [];
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });

            },
            gotoParentCategory() {
                if (this.styleguides.includes(this.currentCategory.id)) {
                    this.loadRootCategories();
                } else {
                    this.loading = true;
                    this.$store.dispatch('assetCategories/getParentCategoryId', {
                        currentCategoryId: this.currentCategory.id
                    }).then((parentCategoryId) => {
                        this.gotoCategoryPage(parentCategoryId);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            updateItems(items) {
                this.entries = items;
            },
            onEntryClick(entry) {
                this.$router.push({
                    name: 'Asset',
                    params: {
                        aid: entry.id
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-list-narrow {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }

    .no_data_msg {
        @extend %font-body2;
    }

    .category-header {
        padding-top: 15px;

        .category-name {
            @extend %font-h2;
            margin-bottom: 16px;
        }

        .category-description {
            @extend %font-body2;

            margin-top: 8px;
            margin-bottom: 16px;

            &:empty {
                display: none;
            }
        }
    }

    .card-container + .card-container {
        margin-top: 32px;
    }

    .card-container.viewmode-list-narrow + .card-container.viewmode-list-narrow {
        margin-top: 16px;
    }
</style>
